import { render, staticRenderFns } from "./index.vue?vue&type=template&id=15a7f189&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlockGalleryBanner: require('/var/www/html/fantini/frontend/components/block/gallery/banner/index.vue').default,BlocksWrapper: require('/var/www/html/fantini/frontend/components/blocks-wrapper/index.vue').default,GridCssLauncherDecorative: require('/var/www/html/fantini/frontend/components/grid/css/launcher/decorative/index.vue').default,Block: require('/var/www/html/fantini/frontend/components/block/index.vue').default,BlockGallerySlide: require('/var/www/html/fantini/frontend/components/block/gallery/slide/index.vue').default,GridCssEditorialMinimal: require('/var/www/html/fantini/frontend/components/grid/css/editorial/minimal/index.vue').default,BlockGalleryMultislide: require('/var/www/html/fantini/frontend/components/block/gallery/multislide/index.vue').default,GridCssLauncherTraditional: require('/var/www/html/fantini/frontend/components/grid/css/launcher/traditional/index.vue').default,BlockSlideVideo: require('/var/www/html/fantini/frontend/components/block/slide/video/index.vue').default,BlockRecirculationNewsletter: require('/var/www/html/fantini/frontend/components/block/recirculation/newsletter/index.vue').default,Page: require('/var/www/html/fantini/frontend/components/page/index.vue').default})
